<template>
  <el-container class="home-container">
    <!-- 头部区域 -->
    <el-header>
      <div>
        <div class="logo-container" @click="goOfferList">
          <div class="app-logo">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M3.5 18.49l6-6.01 4 4L22 6.92l-1.41-1.41-7.09 7.97-4-4L2 16.99z" />
            </svg>
          </div>
          <span class="logo-text">调价先锋</span>
        </div>
        <el-menu
          class="menu"
          text-color="#000"
          active-text-color="#1890ff"
          :unique-opened="true"
          :collapse-transition="false"
          :router="true"
          :default-active="activePath"
          mode="horizontal"
        >
          <!-- 一级菜单 -->
          <el-submenu :index="item.id + ''" v-for="item in menuList" :key="item.id">
            <!-- 一级菜单的模板区域 -->
            <template slot="title">
              <!-- 图标 -->
              <i :class="iconsObj[item.id]"></i>
              <!-- 文本 -->
              <span>{{ item.authName }}</span>
            </template>
            <!-- 二级菜单 -->
            <el-menu-item
              :index="'/' + sunItem.path"
              v-for="sunItem in item.children"
              :key="sunItem.id"
              @click="saveActivePath('/' + sunItem.path)"
            >
              <template slot="title">
                <!-- 二级菜单的模板区域 -->
                <i class="el-icon-menu"></i>
                <!-- 图标 -->
                <span>{{ sunItem.authName }}</span>
                <!-- 文本 -->
              </template>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
      <div>
        <el-button type="text" icon="el-icon-question" @click="more" class="help-button">
          使用说明
        </el-button>
        <el-popover placement="bottom" width="150" trigger="click">
          <div slot="reference">
            <div class="user-info-wrap">
              <div class="user-info">登录账号：{{ userInfo.username }}</div>
              <i class="el-icon-arrow-down"></i>
            </div>
            <div class="nearly-time">
              {{ nearlyTime }}
            </div>
          </div>

          <div class="action-list">
            <!-- <div class="item" @click="goUserCenter">个人中心</div> -->
            <div class="item" @click="editUserInfo">修改密码</div>
            <div class="item" @click="logout">退出</div>
          </div>
        </el-popover>
      </div>
    </el-header>
    <el-container class="content-container">
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
    <el-dialog
      :close-on-click-modal="false"
      title="修改密码"
      :visible.sync="visible"
      width="50%"
      @close="dialogClosed"
    >
      <el-form :model="userInfo" ref="userForm" :rules="rule" label-width="100px">
        <el-form-item label="用户名:" prop="username">
          <el-input
            style="width: 350px"
            v-model.trim="userInfo.username"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="旧密码:" prop="password">
          <el-input
            style="width: 350px"
            v-model.trim="userInfo.password"
            show-password
            type="password"
          ></el-input>
        </el-form-item>

        <el-form-item label="新密码:" prop="newPassword1">
          <el-input
            style="width: 350px"
            v-model.trim="userInfo.newPassword1"
            show-password
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认新密码:" prop="newPassword2">
          <el-input
            style="width: 350px"
            v-model.trim="userInfo.newPassword2"
            show-password
            type="password"
          ></el-input>
        </el-form-item>
      </el-form>
      <el-button :loading="loading" size="small" type="primary" @click="save"
        >保存</el-button
      >
    </el-dialog>
  </el-container>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
export default {
  name: "Home",
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.userInfo.password1) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      nearlyTime: "",
      // 用户信息相关
      userInfo: {},
      rule: {
        password: [{ required: true, message: "请输入旧密码", trigger: "blur" }],
        newPassword1: [{ required: true, message: "请输入新密码", trigger: "blur" }],
        newPassword2: [
          {
            required: true,
            message: "再次请输入新密码",
            trigger: "blur",
            validate: validatePass2,
          },
        ],
        apiKey: [{ required: true, message: "请输入apiKey", trigger: "blur" }],
      },
      loading: false,
      visible: false,
      // 左侧菜单数据
      menuList: [],
      iconsObj: {
        // 一级菜单的icon图标
        101: "iconfont icon-users",
        102: "el-icon-odometer",
        103: "iconfont icon-tijikongjian",
        104: "el-icon-user-solid",
        147: "el-icon-magic-stick",
        125: "iconfont icon-users",
        145: "iconfont icon-baobiao",
      },
      activePath: "",
      welcome: {
        authName: "欢迎光临",
        id: 147,
        order: 1,
        path: "/welcome",
        children: [
          {
            authName: "Welcome",
            id: 124,
            order: 1,
            path: "welcome",
            children: [],
          },
        ],
      },
    };
  },

  watch: {
    $route: {
      immediate: true,
      async handler(val) {
        this.activePath = val.path;
        if (!this.userInfo.username) {
          await this.getUser();
          this.getMenuList();
        }
      },
    },
  },
  async created() {
    this.getMenuList();
    // this.activePath = window.sessionStorage.getItem('activePath')
  },
  methods: {
    convertSecondsToHMS(seconds) {
      let hours = Math.floor(seconds / 3600);
      let minutes = Math.floor((seconds % 3600) / 60);
      let remainingSeconds = seconds % 60;
      return hours + "小时 " + minutes + "分钟 " + remainingSeconds + "秒";
    },

    more() {
      window.open("https://vip.wortenprice.com/help.html", "_blank");
    },
    ...mapMutations({
      setCollapse: "SET_COLLAPSE",
    }),
    ...mapActions(["getUserInfo", "updateUserInfo", "logOut"]),
    // 获取用户信息
    async getUser() {
      const info = await this.getUserInfo();
      // this.userInfo.username = info.username
      this.userInfo = info;
      this.getNearlyTime();
      // window.localStorage.setItem('rule', this.userInfo.role)
    },
    getNearlyTime() {
      let now = Date.now() / 1000; // 获取当前时间戳并转换为秒
      let { to } = this.userInfo;
      if (to <= now) {
        this.nearlyTime = "当前账户已到期";
      } else {
        let days = Math.ceil((to - now) / (60 * 60 * 24));
        if (days <= 3) {
          this.nearlyTime = `当前账户有效期已不足${days}天`;
        }
      }
    },
    // 打开用户信息弹窗
    async editUserInfo() {
      await this.getUser();
      this.visible = true;
    },
    // 修改用户信息
    async save() {
      this.$refs.userForm.validate(async (valid) => {
        if (!valid) return;
        this.loading = true;
        try {
          await this.updateUserInfo({
            password: this.userInfo.password,
            newPassword1: this.userInfo.newPassword1,
            newPassword2: this.userInfo.newPassword2,
          });
          this.$message.success("修改成功");
          this.visible = false;
        } catch (e) {
          this.$message.error("修改失败，请重试");
        } finally {
          this.loading = false;
        }
      });
    },
    dialogClosed() {
      this.$refs.userForm.resetFields();
    },
    goUserCenter() {
      this.$router.push({
        path: "/user-center",
      });
    },
    async logout() {
      await this.logOut();
      window.sessionStorage.clear();
      window.localStorage.clear();
      this.$router.push("/login");
    },
    // 获取所有的菜单
    async getMenuList() {
      // 1：管理员 2：商户
      let superAdmin = [
        {
          authName: "超级管理员",
          id: 101,
          order: 1,
          path: "/admin",
          children: [
            {
              authName: "用户列表",
              id: 121,
              order: 1,
              path: "user-list",
              children: [],
            },
            {
              authName: "offer列表",
              id: 122,
              order: 2,
              path: "offer-list",
              children: [],
            },
          ],
        },
      ];

      let shopAdmin = [
        {
          authName: "店铺管理员",
          id: 101,
          order: 1,
          path: "/admin",
          children: [
            {
              authName: "offer列表",
              id: 122,
              order: 2,
              path: "offer-list",
              children: [],
            },
          ],
        },
      ];
      let mall = [
        {
          authName: "我的",
          id: 104,
          order: 1,
          path: "/admin",
          children: [
            {
              authName: "我的店铺",
              order: 1,
              path: "user-center-new",
              children: [],
            },
            // {
            //   authName: '调价中心',
            //   order: 2,
            //   path: 'update-price-center',
            //   children: []
            // }
          ],
        },
      ];
      let rule = window.localStorage.getItem("rule");
      if (+rule === 1) {
        // 管理员
        this.menuList = superAdmin.concat(mall);
      } else if (+rule === 3) {
        this.menuList = shopAdmin.concat(mall);
      } else {
        this.menuList = mall;
      }
    },
    saveActivePath(activePath) {
      // window.sessionStorage.setItem('activePath', activePath)
      this.activePath = activePath;
      // console.log(this.$route.path)
    },
    goOfferList() {
      if (this.$route.path !== "/user-center-new") {
        this.$router.push("/user-center-new");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.nearly-time {
  color: #f56c6c;
  font-size: 13px;
  font-weight: 500;
  margin-top: 4px;
  padding: 0 12px;
}

.user-info-wrap {
  display: flex;
  align-items: center;
  color: #606266;
  cursor: pointer;
  padding: 6px 12px;
  border-radius: 6px;
  transition: all 0.3s;
  
  &:hover {
    background-color: #f5f7fa;
    color: #409EFF;
    
    .el-icon-arrow-down {
      transform: rotate(180deg);
      color: #409EFF;
    }
  }

  .user-info {
    font-size: 14px;
    max-width: 150px;
    margin-right: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
  }
  
  .el-icon-arrow-down {
    font-size: 12px;
    transition: all 0.3s;
  }
}

.help-button {
  font-size: 14px;
  padding: 8px 16px;
  margin-right: 16px;
  border-radius: 4px;
  transition: all 0.3s;

  &:hover {
    background: rgba(64, 158, 255, 0.1);
    color: #409EFF;
  }

  i {
    margin-right: 6px;
    font-size: 16px;
  }
}

.action-list {
  .item {
    height: 36px;
    line-height: 36px;
    font-size: 14px;
    cursor: pointer;
    padding: 0 12px;
    border-radius: 4px;
    transition: all 0.2s;
    margin-bottom: 4px;
    
    &:last-child {
      margin-bottom: 0;
    }
    
    &:hover {
      background-color: #f5f7fa;
      color: #409EFF;
    }
  }
}

.content-container {
  height: calc(100% - 60px);
  position: relative;
}

.home-container {
  width: 100vw;
  height: 100%;
}

.el-header {
  display: flex;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  background-color: #fff;
  align-items: center;
  box-shadow: 0 2px 10px rgba(0, 21, 41, 0.08);
  font-size: 20px;
  height: 60px !important;

  > div {
    display: flex;
    align-items: center;
  }
}

.el-aside {
  background-color: #333744;

  .el-menu {
    border-right: none;
  }
}

.el-main {
  background-color: #f5f7fa;
  width: calc(100% - 200px);
  padding: 20px;
  overflow-y: auto;
  
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #c0c4cc;
    border-radius: 4px;
  }
  
  &::-webkit-scrollbar-track {
    background: #f5f7fa;
  }
}

// * 添加 logo 相关样式 */
.logo-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 24px;
  padding: 6px 8px;
  border-radius: 6px;
  transition: all 0.3s;
  
  &:hover {
    background-color: #f5f7fa;
    
    .app-logo {
      transform: translateY(-2px);
      box-shadow: 0 6px 15px rgba(0, 113, 227, 0.3);
    }
    
    .logo-text {
      background: linear-gradient(135deg, #0062c4, #0095ff);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.app-logo {
  width: 36px;
  height: 36px;
  background: linear-gradient(135deg, #0071e3, #00c6ff);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 10px rgba(0, 113, 227, 0.2);
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.app-logo::before {
  content: '';
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
  transform: rotate(45deg);
  pointer-events: none;
}

.app-logo svg {
  width: 20px;
  height: 20px;
  fill: white;
  filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.2));
}

.logo-text {
  font-size: 18px;
  font-weight: 600;
  margin-left: 10px;
  background: linear-gradient(135deg, #0071e3, #00c6ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: -0.5px;
  transition: all 0.3s;
}

.menu {
  border-bottom: none !important;
  margin-left: 10px;
  
  /deep/ .el-submenu__title {
    height: 60px;
    line-height: 60px;
    transition: all 0.3s;
    font-weight: 500;
    
    &:hover {
      background-color: #f5f7fa !important;
    }
    
    i {
      color: #606266;
      margin-right: 4px;
    }
  }
  
  /deep/ .el-menu-item {
    transition: all 0.3s;
    
    &:hover {
      background-color: #f5f7fa !important;
    }
    
    &.is-active {
      color: #409EFF !important;
      font-weight: 500;
      background-color: rgba(64, 158, 255, 0.1) !important;
    }
  }
}

.iconfont {
  padding-right: 10px;
}

.toggle-button {
  background: #4a5064;
  font-size: 10px;
  text-align: center;
  line-height: 24px;
  color: #fff;
  letter-spacing: 0.2em;
  cursor: pointer;
}

/deep/ .el-dialog {
  border-radius: 8px;
  overflow: hidden;
  
  .el-dialog__header {
    background-color: #f5f7fa;
    padding: 16px 20px;
    
    .el-dialog__title {
      font-size: 16px;
      font-weight: 600;
      color: #303133;
    }
  }
  
  .el-dialog__body {
    padding: 24px 20px;
  }
  
  .el-dialog__footer {
    padding: 12px 20px 20px;
    text-align: right;
  }
}

/deep/ .el-form-item__label {
  font-weight: 500;
}

/deep/ .el-button--primary {
  padding: 10px 20px;
  font-weight: 500;
  border-radius: 4px;
  transition: all 0.3s;
  
  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(64, 158, 255, 0.3);
  }
}
</style>